import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/datadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/footer/siteFooter/SiteFooterLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/PageSetup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/VisitorSetup.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/sb-shell/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/builder/clientUtils.tsx");
